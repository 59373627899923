:root {
        --primary-color: #004899;
        --color: white;
      }
div#website { display: block; }

.Start_1::after {
  display: flex;
  align-items: center;
  flex-direction: column;
  content: url('../Assets/Icons/Start_1.svg')!important;
  background-color: var(--primary-color)!important;
}