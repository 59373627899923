@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css');
@import url('~/node_modules/pannellum/src/css/pannellum.css');
@import url('./custom.scss');

:root {
  font-family: 'Inter', sans-serif !important;
  --bs-body-font-family: 'Inter', sans-serif !important;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: 'Inter var', sans-serif !important;
  }
}
.pnlm-container {
  contain: unset !important; // This prevents an obscure visual bug in older safari versions.
  font-family: 'Inter', sans-serif;
  background-color: black !important;
  background-image: none !important;
}
:root {
  --border-radius: 5px;
  font-size: 18px;
  font-weight: 200;
}
* {
  margin: 0;
  padding: 0;
}
p {
  margin-bottom: 0 !important;
}
#panorama {
  position: absolute;
  width: 100%;
  height: 100vh; /* Leave as fallback for browsers without dvh support (notably Firefox). */
  height: 100dvh; /* Relevant for mobile browsers. */
}

// We override the default modal background, to make sure it works in fullscreen mode.
.modal {
  background: rgba(0, 0, 0, 0.7);
}

.modal-backdrop {
  display: none;
}

.modal-content {
  border: none;
  color: var(--color);
  background: transparent;
}

/* Videomodal styling */
.video-div {
  padding: 0;
  text-align: right;
}
.video-wrapper {
  background: black;
  aspect-ratio: var(--aspect-ratio, 0.5625);
  position: relative;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.btn-close {
  opacity: 1 !important;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  height: 50px;
  width: 50px;
}

/* Question / form modal styling */
form {
  text-align: center;
}
.btn-outline-primary {
  text-align: left;
  color: var(--color);
  background-color: transparent;
  border: 1px solid var(--color);
}
.btn-outline-primary:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--color) !important;
}
.btn-outline-primary i.bi-arrow-right-short {
  position: absolute;
  opacity: 0 !important;
  transition: opacity 0.5s;
}
.btn-outline-primary:hover i.bi-arrow-right-short {
  opacity: 1 !important;
}
.btn-outline-primary i.bi-check {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}
.btn-outline-primary span {
  transition: padding-left 0.5s;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.btn-outline-primary:hover span {
  padding-left: 1.5rem;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.btn-check:checked + .btn {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--color) !important;
  pointer-events: none;
}
.btn-check:checked + .btn i.bi-check {
  opacity: 1 !important;
}
.btn-check:checked + .btn i.bi-arrow-right-short {
  opacity: 0 !important;
}
.btn-check:checked + .btn span {
  padding-left: 1.5rem;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.btn-primary {
  margin: 1rem auto 0;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--color) !important;
}
.btn-primary i {
  vertical-align: middle;
  margin-left: 0.5rem;
}
.btn-primary:hover {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: var(--primary-color) !important;
}
span hr {
  margin: 0 !important;
  opacity: 1 !important;
}

/* Hotspotstyling */
div.custom-hotspot span {
  visibility: visible !important;
  white-space: nowrap;
  transition: transform 0.5s;
}
div.custom-hotspot:hover span {
  transform: translateY(-5px);
}
div.custom-hotspot:hover span:after {
  border-color: var(--primary-color) transparent transparent transparent;
}
div.custom-hotspot span:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: var(--primary-color) transparent transparent transparent;
  bottom: -20px;
  left: -10px;
  margin: 0 50%;
}
.custom-hotspot:after {
  box-sizing: unset;
  -webkit-animation: pulse-border 2s infinite;
  animation: pulse-border 2s infinite;
  background-color: transparent;
  border: 0.5rem solid var(--primary-color);
  border-radius: 100%;
  box-shadow: 0 0 0 0 var(--primary-color);
  content: '';
  height: 2.5rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 2.5rem;
}
div.pnlm-tooltip span {
  background-color: var(--primary-color);
  color: var(--color);
  padding: 0;
  bottom: 50px;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  max-width: none;
}
@-webkit-keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0 var(--primary-color);
    transform: translate(-50%, -50%) scale(0.95);
  }
  70% {
    box-shadow: 0 0 0 10px transparent;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    box-shadow: 0 0 0 0 transparent;
    transform: translate(-50%, -50%) scale(0.95);
  }
}
@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0 var(--primary-color);
    transform: translate(-50%, -50%) scale(0.95);
  }
  70% {
    box-shadow: 0 0 0 10px transparent;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    box-shadow: 0 0 0 0 transparent;
    transform: translate(-50%, -50%) scale(0.95);
  }
}
.hidden-hotspot::after {
  opacity: 0 !important;
}
// Controls styling
div.ctrl {
  z-index: 2;
  padding: 5px;
  border-radius: 100%;
  background-color: var(--primary-color);
}
div#fullscreen {
  position: absolute;
  left: 2%;
  top: 2%;
}
div#compass {
  position: absolute;
  left: 2%;
  top: calc(4% + 50px);
}
@media only screen and (min-width: 768px) {
  div#compass {
    display: none;
  }
  div.ctrl:hover svg,
  div.conversion-option:hover svg {
    transition: transform 0.2s;
    transform: scale(1.2);
  }
}
div.ctrl svg {
  fill: var(--color);
}
div.conversion-option svg {
  fill: var(--primary-color);
}
div.bottom-controls {
  position: absolute;
  bottom: calc(
    env(safe-area-inset-bottom) + 2%
  ); // To prevent buttons falling off the screen on mobile browsers.
  left: 2%;
  z-index: 2;
  display: flex;
  width: 125px;
  justify-content: space-between;
}
div.conversion-options {
  overflow: hidden;
  transition: max-height 0.8s ease;
  max-height: 0;
  position: absolute;
  bottom: calc(2% + 50px);
  left: calc(2% + 80px);
  z-index: 2;
}
.conversion-options.open {
  max-height: 500px;
}
div.conversion-option {
  display: none;
  margin-bottom: 1vh;
  z-index: 2;
  padding: 5px;
  border-radius: 100%;
  background-color: var(--color);
}
div.ctrl:hover,
div.conversion-option:hover {
  cursor: pointer;
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 75vw !important;
  }
}
.pnlm-container * {
  box-sizing: unset !important;
}
/* Scrollbar styling, please note that the ::-webkit-scrollbar needs to contain at least one style attribute, otherwise none of the other scrollbar styling will work. */
::-webkit-scrollbar {
  max-width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #333333;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666666;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

.pnlm-load-box {
  display: none !important;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  height: 70px;
  width: 70px;
  color: var(--primary-color);
  display: inline-block;
  border: 6px solid;
  border-radius: 50%;
  border-top-color: transparent;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
span.pnlm-pointer {
  width: unset !important;
}
