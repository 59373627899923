@import "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css";
.pnlm-container {
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  contain: content;
  background: #f4f4f4 url("background.1abb46fc.svg");
  outline: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Helvetica, Nimbus Sans L, Liberation Sans, Arial, sans-serif;
  line-height: 1.4;
  position: relative;
  overflow: hidden;
}

.pnlm-ui {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}

.pnlm-grab {
  cursor: grab;
  cursor: url("grab.ff6462b8.svg") 12 8, default;
}

.pnlm-grabbing {
  cursor: grabbing;
  cursor: url("grabbing.305f9b6d.svg") 12 8, default;
}

.pnlm-sprite {
  background-image: url("sprites.f0e96451.svg");
}

.pnlm-container:fullscreen {
  width: 100% !important;
  height: 100% !important;
  position: static !important;
}

.pnlm-render-container {
  cursor: inherit;
  width: 100%;
  height: 100%;
  position: absolute;
}

.pnlm-controls {
  cursor: pointer;
  z-index: 2;
  background-color: #fff;
  border: 1px solid #0006;
  border-radius: 3px;
  margin-top: 4px;
  transform: translateZ(9999px);
}

.pnlm-control:hover {
  background-color: #f8f8f8;
}

.pnlm-controls-container {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 4px;
}

.pnlm-zoom-controls {
  width: 26px;
  height: 52px;
}

.pnlm-zoom-in {
  border-radius: 3px 3px 0 0;
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
}

.pnlm-zoom-out {
  background-position: 0 -26px;
  border-top: 1px solid #0000001a;
  border-radius: 0 0 3px 3px;
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
}

.pnlm-fullscreen-toggle-button, .pnlm-orientation-button, .pnlm-hot-spot-debug-indicator {
  width: 26px;
  height: 26px;
}

.pnlm-hot-spot-debug-indicator {
  background-color: #ffffff80;
  border-radius: 13px;
  width: 26px;
  height: 26px;
  margin: -13px 0 0 -13px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.pnlm-orientation-button-inactive {
  background-position: 0 -156px;
}

.pnlm-orientation-button-active {
  background-position: 0 -182px;
}

.pnlm-fullscreen-toggle-button-inactive {
  background-position: 0 -52px;
}

.pnlm-fullscreen-toggle-button-active {
  background-position: 0 -78px;
}

.pnlm-panorama-info {
  color: #fff;
  text-align: left;
  z-index: 2;
  background-color: #000000b3;
  border-radius: 0 3px 3px 0;
  padding-right: 10px;
  display: none;
  position: absolute;
  bottom: 4px;
  transform: translateZ(9999px);
}

.pnlm-title-box {
  margin-bottom: 3px;
  padding-left: 5px;
  font-size: 20px;
  display: table;
  position: relative;
}

.pnlm-author-box {
  padding-left: 5px;
  font-size: 12px;
  display: table;
  position: relative;
}

.pnlm-load-box {
  text-align: center;
  color: #fff;
  background-color: #000000b3;
  border-radius: 3px;
  width: 200px;
  height: 150px;
  margin: -75px 0 0 -100px;
  font-size: 20px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.pnlm-load-box p {
  margin: 20px 0;
}

.pnlm-lbox {
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.pnlm-loading {
  background-color: #fff;
  width: 10px;
  height: 10px;
  animation-name: pnlm-mv;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: relative;
}

@keyframes pnlm-mv {
  from {
    top: 0;
    left: 0;
  }

  25% {
    top: 0;
    left: 10px;
  }

  50% {
    top: 10px;
    left: 10px;
  }

  75% {
    top: 10px;
    left: 0;
  }

  to {
    top: 0;
    left: 0;
  }
}

.pnlm-load-button {
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #000000b3;
  border-radius: 3px;
  width: 200px;
  height: 100px;
  margin: -50px 0 0 -100px;
  font-size: 20px;
  display: table;
  position: absolute;
  top: 50%;
  left: 50%;
}

.pnlm-load-button:hover {
  background-color: #000c;
}

.pnlm-load-button p {
  vertical-align: middle;
  display: table-cell;
}

.pnlm-info-box {
  text-align: center;
  color: #fff;
  table-layout: fixed;
  background-color: #000;
  border-radius: 3px;
  width: 200px;
  height: 150px;
  margin: -75px 0 0 -100px;
  font-size: 15px;
  display: table;
  position: absolute;
  top: 50%;
  left: 50%;
}

.pnlm-info-box a, .pnlm-author-box a {
  color: #fff;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.pnlm-info-box p {
  vertical-align: middle;
  padding: 0 5px;
  display: table-cell;
}

.pnlm-error-msg {
  display: none;
}

.pnlm-about-msg {
  color: #fff;
  opacity: 0;
  -o-transition: opacity .3s ease-in-out;
  z-index: 1;
  background: #000000b3;
  border-radius: 3px;
  padding: 5px 8px;
  font-size: 11px;
  line-height: 11px;
  transition: opacity .3s ease-in-out;
  display: none;
  position: absolute;
  top: 50px;
  left: 50px;
}

.pnlm-about-msg a:link, .pnlm-about-msg a:visited {
  color: #fff;
}

.pnlm-about-msg a:hover, .pnlm-about-msg a:active {
  color: #eee;
}

.pnlm-hotspot-base {
  visibility: hidden;
  cursor: default;
  vertical-align: middle;
  z-index: 1;
  position: absolute;
  top: 0;
}

.pnlm-hotspot {
  border-radius: 13px;
  width: 26px;
  height: 26px;
}

.pnlm-hotspot:hover {
  background-color: #fff3;
}

.pnlm-hotspot.pnlm-info {
  background-position: 0 -104px;
}

.pnlm-hotspot.pnlm-scene {
  background-position: 0 -130px;
}

div.pnlm-tooltip span {
  visibility: hidden;
  color: #fff;
  text-align: center;
  cursor: default;
  background-color: #000000b3;
  border-radius: 3px;
  max-width: 200px;
  margin-left: -220px;
  padding: 5px 10px;
  position: absolute;
}

div.pnlm-tooltip:hover span {
  visibility: visible;
}

div.pnlm-tooltip:hover span:after {
  content: "";
  border: 10px solid #0000;
  border-top-color: #000000b3;
  width: 0;
  height: 0;
  margin: 0 50%;
  position: absolute;
  bottom: -20px;
  left: -10px;
}

.pnlm-compass {
  cursor: default;
  background-image: url("compass.d5596884.svg");
  border-radius: 25px;
  width: 50px;
  height: 50px;
  display: none;
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.pnlm-world {
  position: absolute;
  top: 50%;
  left: 50%;
}

.pnlm-face {
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  position: absolute;
}

.pnlm-dragfix, .pnlm-preview-img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.pnlm-preview-img {
  background-position: center;
  background-size: cover;
}

.pnlm-lbar {
  border: 1px solid #fff;
  width: 150px;
  height: 6px;
  margin: 0 auto;
}

.pnlm-lbar-fill {
  background: #fff;
  width: 0;
  height: 100%;
}

.pnlm-lmsg {
  font-size: 12px;
}

.pnlm-fade-img {
  position: absolute;
  top: 0;
  left: 0;
}

.pnlm-pointer {
  cursor: pointer;
}

:root {
  --primary-color: #004899;
  --color: white;
}

div#website {
  display: block;
}

.Start_1:after {
  flex-direction: column;
  align-items: center;
  display: flex;
  content: url("Start_1.bed50397.svg") !important;
  background-color: var(--primary-color) !important;
}

:root {
  --bs-body-font-family: "Inter", sans-serif !important;
  font-family: Inter, sans-serif !important;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: Inter var, sans-serif !important;
  }
}

.pnlm-container {
  font-family: Inter, sans-serif;
  contain: unset !important;
  background-color: #000 !important;
  background-image: none !important;
}

:root {
  --border-radius: 5px;
  font-size: 18px;
  font-weight: 200;
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 0 !important;
}

#panorama {
  width: 100%;
  height: 100dvh;
  position: absolute;
}

.modal {
  background: #000000b3;
}

.modal-backdrop {
  display: none;
}

.modal-content {
  color: var(--color);
  background: none;
  border: none;
}

.video-div {
  text-align: right;
  padding: 0;
}

.video-wrapper {
  aspect-ratio: var(--aspect-ratio, .5625);
  background: #000;
  position: relative;
}

.video-wrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btn-close {
  z-index: 20;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1 !important;
}

form {
  text-align: center;
}

.btn-outline-primary {
  text-align: left;
  color: var(--color);
  border: 1px solid var(--color);
  background-color: #0000;
}

.btn-outline-primary:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--color) !important;
}

.btn-outline-primary i.bi-arrow-right-short {
  transition: opacity .5s;
  position: absolute;
  opacity: 0 !important;
}

.btn-outline-primary:hover i.bi-arrow-right-short {
  opacity: 1 !important;
}

.btn-outline-primary i.bi-check {
  opacity: 0;
  transition: opacity .5s;
  position: absolute;
}

.btn-outline-primary span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  transition: padding-left .5s;
}

.btn-outline-primary:hover span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-left: 1.5rem;
}

.btn-check:checked + .btn {
  pointer-events: none;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--color) !important;
}

.btn-check:checked + .btn i.bi-check {
  opacity: 1 !important;
}

.btn-check:checked + .btn i.bi-arrow-right-short {
  opacity: 0 !important;
}

.btn-check:checked + .btn span {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-left: 1.5rem;
}

.btn-primary {
  margin: 1rem auto 0;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--color) !important;
}

.btn-primary i {
  vertical-align: middle;
  margin-left: .5rem;
}

.btn-primary:hover {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: var(--primary-color) !important;
}

span hr {
  opacity: 1 !important;
  margin: 0 !important;
}

div.custom-hotspot span {
  white-space: nowrap;
  transition: transform .5s;
  visibility: visible !important;
}

div.custom-hotspot:hover span {
  transform: translateY(-5px);
}

div.custom-hotspot:hover span:after {
  border-color: var(--primary-color) transparent transparent transparent;
}

div.custom-hotspot span:after {
  content: "";
  border-style: solid;
  border-width: 10px;
  border-color: var(--primary-color) transparent transparent transparent;
  width: 0;
  height: 0;
  margin: 0 50%;
  position: absolute;
  bottom: -20px;
  left: -10px;
}

.custom-hotspot:after {
  box-sizing: unset;
  border: .5rem solid var(--primary-color);
  box-shadow: 0 0 0 0 var(--primary-color);
  content: "";
  background-color: #0000;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  animation: 2s infinite pulse-border;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1);
}

div.pnlm-tooltip span {
  background-color: var(--primary-color);
  color: var(--color);
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
  max-width: none;
  padding: .5rem;
  bottom: 50px;
}

@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0 var(--primary-color);
    transform: translate(-50%, -50%)scale(.95);
  }

  70% {
    transform: translate(-50%, -50%)scale(1);
    box-shadow: 0 0 0 10px #0000;
  }

  to {
    transform: translate(-50%, -50%)scale(.95);
    box-shadow: 0 0 #0000;
  }
}

.hidden-hotspot:after {
  opacity: 0 !important;
}

div.ctrl {
  z-index: 2;
  background-color: var(--primary-color);
  border-radius: 100%;
  padding: 5px;
}

div#fullscreen {
  position: absolute;
  top: 2%;
  left: 2%;
}

div#compass {
  position: absolute;
  top: calc(4% + 50px);
  left: 2%;
}

@media only screen and (width >= 768px) {
  div#compass {
    display: none;
  }

  div.ctrl:hover svg, div.conversion-option:hover svg {
    transition: transform .2s;
    transform: scale(1.2);
  }
}

div.ctrl svg {
  fill: var(--color);
}

div.conversion-option svg {
  fill: var(--primary-color);
}

div.bottom-controls {
  bottom: calc(env(safe-area-inset-bottom)  + 2%);
  z-index: 2;
  justify-content: space-between;
  width: 125px;
  display: flex;
  position: absolute;
  left: 2%;
}

div.conversion-options {
  z-index: 2;
  max-height: 0;
  transition: max-height .8s;
  position: absolute;
  bottom: calc(2% + 50px);
  left: calc(2% + 80px);
  overflow: hidden;
}

.conversion-options.open {
  max-height: 500px;
}

div.conversion-option {
  z-index: 2;
  background-color: var(--color);
  border-radius: 100%;
  margin-bottom: 1vh;
  padding: 5px;
  display: none;
}

div.ctrl:hover, div.conversion-option:hover {
  cursor: pointer;
}

@media (width >= 1200px) {
  .modal-xl {
    --bs-modal-width: 75vw !important;
  }
}

.pnlm-container * {
  box-sizing: unset !important;
}

::-webkit-scrollbar {
  max-width: 4px;
}

::-webkit-scrollbar-track {
  background: #333;
}

::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.pnlm-load-box {
  display: none !important;
}

.loading-spinner {
  width: 70px;
  height: 70px;
  color: var(--primary-color);
  border: 6px solid;
  border-top-color: #0000;
  border-radius: 50%;
  animation: 1s linear infinite rotate;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

span.pnlm-pointer {
  width: unset !important;
}
/*# sourceMappingURL=index.3f5e2981.css.map */
